export const home = [
  {
    header: '',
    content: 'Addition',
    excerpt: '(By range)',
    action: 'add',
  },

  {
    header: '',
    content: 'Subtraction',
    excerpt: '(By range)',
    action: 'sub',
  },

  {
    header: '',
    content: 'Multiplication',
    excerpt: '(Times tables or by range)',
    action: 'mult',
  },

  {
    header: '',
    content: 'Division',
    excerpt: '(By range or difficulty)',
    action: 'div',
  },
];
