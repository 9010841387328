import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ResultsAccordion from './ResultsAccordion';
import { Button, Card, CardActions, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { HomeOutlined } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
  table: {
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   width: '50%',
    // },
  },
  grid: {
    margin: 10,
    alignItems: 'stretch',
  },
  item: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const RelaxedResults = ({results, symbol}) => {
  const history = useHistory();
  const classes = useStyles();
  const [stats, setStats] = useState({
    total: 0,
    correct: 0,
    incorrect: 0,
    skipped: 0,
  });
  
  /*eslint-disable eqeqeq*/
  useEffect(() => {
    let corr = 0;
    let icor = 0;
    let skip = 0;
    results.forEach(row => {
      if (row.r == row.e) {
        corr++;
      } else if (row.r != row.e && row.t !== 'skip') {
        icor++;
      } else if (row.t === 'skip') {
        skip++;
      }
    });
    setStats((prevState) => ({
      ...prevState,
      total: results.length,
      correct: corr,
      incorrect: icor,
      skipped: skip,
    }));
  }, [results]);

  return (
    <Grid container spacing={1} className={classes.grid}>
      <Grid item xs={12} sm={6} className={classes.item}>
        <Card variant='outlined'>
          <CardContent>
            <Typography variant='h6' color='textSecondary'>
              Summary
            </Typography>

            {stats.correct === stats.total ? (
              <Typography>
                Great job! You answered all the questions correctly.
              </Typography>
            ) : (
              <React.Fragment>
                <Typography>Correct: {stats.correct}</Typography>
                <Divider />
                <Typography>Incorrect: {stats.incorrect}</Typography>
                <Divider />
                <Typography>Skipped: {stats.skipped}</Typography>
              </React.Fragment>
            )}
            <Divider />
          </CardContent>
          <CardActions>
            <Button
              size='small'
              color='primary'
              variant='contained'
              endIcon={<HomeOutlined />}
              onClick={() => history.push('/')}
            >
              Home
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.item}>
        <Card variant='outlined'>
          <CardContent>
            <Typography variant='h6' color='textSecondary'>
              Your answers
            </Typography>

            <ResultsAccordion results={results} symbol={symbol} stats={stats} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default RelaxedResults;