import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';

import Image from '../../css/bb_640.jpg';
import { Rating } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    width: '100%',
    margin: '10px',
  },
  card: {
    color: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    // background: `url(${Image})`,
    background: `linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(${Image})`,
    // background: 'linear-gradient(to right bottom, #430089, #82ffa1)',
    backgroundPosition: 'center top',
    '&:hover': {
      background: `linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)), url(${Image})`,
      backgroundPosition: 'center top',
    },
  },
  content: {
    textAlign: 'center',
  },
  actions: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    //justifyContent: 'space-between',
  },
  lBox: {
    justifyContent: 'flex',
  },
  avatar: {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
}));

const HomeCard = (props) => {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.paper}>
      <Card className={classes.card}>
        <CardHeader
          title={props.header}
          subheader={props.subheader}
          avatar={<Avatar className={classes.avatar}>{props.avatar}</Avatar>}
        />

        <CardActionArea onClick={() => props.action1(props.selection1)}>
          <CardContent className={classes.content}>
            <Typography gutterBottom variant='h5' component='h2'>
              {props.content}
            </Typography>
            <Typography component='p' variant='overline'>
              {props.excerpt}
            </Typography>
          </CardContent>
        </CardActionArea>

        <CardActions className={classes.actions}>
          <Box className={classes.lBox}>
          {props.rating && (
              <Rating
                name='half-rating-read'
                defaultValue={props.rating}
                precision={0.5}
                readOnly
              />
          )}
            
          </Box>
            {props.action1 && (
              <Button
                size='small'
                color={`${props.action1Color || 'primary'}`}
                // color='default'
                variant='contained'
                endIcon={props.action1Icon}
                onClick={() => props.action1(props.selection1)}
              >
                {props.action1Text}
              </Button>
            )}

            {props.action2 && (
              <Button
                size='small'
                color={`${props.action2Color || 'primary'}`}
                variant='contained'
                endIcon={props.action2Icon}
                onClick={() => props.action2(props.selection2)}
              >
                {props.action2Text}
              </Button>
            )}
        </CardActions>
      </Card>
    </Paper>
  );
};

export default HomeCard;
