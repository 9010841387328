import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Image from '../css/memphis-colorful.png';
import { Grid } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    height: '3.5em',
    '& .MuiToolbar-regular': {
      minHeight: '3.5em',
    },
    zIndex: 1400,
    // zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawerPaper: {
    width: 240,
    // background: `url(${Image})`,
    background: `linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), url(${Image})`,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3.5),
  },
  closeMenuButton: {
    marginRight: 0,
    marginLeft: 'auto',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: '0px 15px 15px 10px',
  },
  logo: {
    color: '#fff',
    fontSize: '1.4em',
    textTransform: 'unset',
    textDecoration: 'unset',
  },
  link: {
    textTransform: 'unset',
    textDecoration: 'unset',
    //color: theme.palette.primary.dark,
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    background: `linear-gradient(45deg, ${theme.palette.primary.dark} 30%, ${theme.palette.primary.light} 95%)`,
    

    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '10px 70px',
    width: '100%',
    //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .30)',
    boxShadow: '0px 3px 5px 2px rgba(0, 0, 0, .30)',
  },
}));

const ResponsiveNav = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          <Grid container alignItems='center'>
            <Grid item>
              <IconButton
                color='inherit'
                aria-label='Open drawer'
                edge='start'
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item>

              <NavLink
                to='/'
                className={classes.logo}
                onClick={() => setMobileOpen(false)}
              >
                    Learning Loop
              </NavLink>

            </Grid>

            <Grid item xs />

            <Grid item></Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <nav>
        <Drawer
          variant='temporary'
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <IconButton
            onClick={handleDrawerToggle}
            className={classes.closeMenuButton}
          >
            <CloseIcon />
          </IconButton>

          <Grid container alignItems='center' style={{ marginTop: 15 }}>
            <Grid item xs={12} className={classes.paper}>
              <NavLink
                to='/'
                onClick={handleDrawerToggle}
                className={classes.link}
              >
                Home
              </NavLink>
            </Grid>
            <Grid item xs={12} className={classes.paper}>
              <NavLink
                to='/about'
                onClick={handleDrawerToggle}
                className={classes.link}
              >
                About
              </NavLink>
            </Grid>
            <Grid item xs={12} className={classes.paper}>
              <NavLink
                to='/contact'
                onClick={handleDrawerToggle}
                className={classes.link}
              >
                Contact
              </NavLink>
            </Grid>
          </Grid>
        </Drawer>
      </nav>

      <div className={classes.content}></div>
    </div>
  );
};

export default ResponsiveNav;
