export const options = [
  {
    id: 101,
    topic: 'add',
    header: 'Addition',
    content: 'Level 1',
    excerpt: '(Numbers less than 10)',
  },
  {
    id: 102,
    topic: 'add',
    header: 'Addition',
    content: 'Level 2',
    excerpt: '(Numbers less than 20)',
  },
  {
    id: 103,
    topic: 'add',
    header: 'Addition',
    content: 'Level 3',
    excerpt: '(Numbers less than 100)',
  },
  {
    id: 104,
    topic: 'add',
    header: 'Addition',
    content: 'Level 4',
    excerpt: '(Numbers less than 1,000)',
  },

  {
    id: 201,
    topic: 'sub',
    header: 'Subtraction',
    content: 'Level 1',
    excerpt: '(Numbers less than 10)',
  },
  {
    id: 202,
    topic: 'sub',
    header: 'Subtraction',
    content: 'Level 2',
    excerpt: '(Numbers less than 20)',
  },
  {
    id: 203,
    topic: 'sub',
    header: 'Subtraction',
    content: 'Level 3',
    excerpt: '(Numbers less than 100)',
  },
  {
    id: 204,
    topic: 'sub',
    header: 'Subtraction',
    content: 'Level 4',
    excerpt: '(Numbers less than 1,000)',
  },

  {
    id: 302,
    topic: 'mult',
    header: 'Multiplication',
    content: 'x 2',
    excerpt: '(Times 2)',
  },
  {
    id: 303,
    topic: 'mult',
    header: 'Multiplication',
    content: 'x 3',
    excerpt: '(Times 3)',
  },
  {
    id: 304,
    topic: 'mult',
    header: 'Multiplication',
    content: 'x 4',
    excerpt: '(Times 4)',
  },
  {
    id: 305,
    topic: 'mult',
    header: 'Multiplication',
    content: 'x 5',
    excerpt: '(Times 5)',
  },
  {
    id: 306,
    topic: 'mult',
    header: 'Multiplication',
    content: 'x 6',
    excerpt: '(Times 6)',
  },
  {
    id: 307,
    topic: 'mult',
    header: 'Multiplication',
    content: 'x 7',
    excerpt: '(Times 7)',
  },
  {
    id: 308,
    topic: 'mult',
    header: 'Multiplication',
    content: 'x 8',
    excerpt: '(Times 8)',
  },
  {
    id: 309,
    topic: 'mult',
    header: 'Multiplication',
    content: 'x 9',
    excerpt: '(Times 9)',
  },
  {
    id: 310,
    topic: 'mult',
    header: 'Multiplication',
    content: 'x 10',
    excerpt: '(Times 10)',
  },
  {
    id: 311,
    topic: 'mult',
    header: 'Multiplication',
    content: 'x 11',
    excerpt: '(Times 11)',
  },
  {
    id: 312,
    topic: 'mult',
    header: 'Multiplication',
    content: 'x 12',
    excerpt: '(Times 12)',
  },

  {
    id: 401,
    topic: 'div',
    header: 'Division',
    content: 'Level 1',
    excerpt: '(Numbers less than 20)',
  },
  {
    id: 402,
    topic: 'div',
    header: 'Division',
    content: 'Level 2',
    excerpt: '(Numbers less than 50)',
  },
  {
    id: 403,
    topic: 'div',
    header: 'Division',
    content: 'Level 3',
    excerpt: '(Numbers less than 100)',
  },
  {
    id: 404,
    topic: 'div',
    header: 'Division',
    content: 'Level 4',
    excerpt: '(Numbers less than 1,000)',
  },
];