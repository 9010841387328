import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  InputAdornment,
  Paper,
  Grid,
  Container,
  Typography,
} from '@material-ui/core';

import AccountBox from '@material-ui/icons/AccountBox';
import EmailIcon from '@material-ui/icons/Email';
import MessageIcon from '@material-ui/icons/Message';
import SendIcon from '@material-ui/icons/Send';
import './contact.css';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({name: false, email: false, message: false});
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (window.location.search.includes('success=true')) {
      setSuccess(true);
    }
  }, []);

  const encode = data => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  };

  const handleSubmit = event => {
    event.preventDefault();
    setErrors(prevState => {
      return {...prevState, name: !name, email: !email, message: !message }
    });

    if (name && email && message) {
      const submissionObj = {
        name, email, message
      }
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contact', ...submissionObj }),
      })
        .then(() => {
          setSuccess(true);
          setName('');
          setEmail('');
          setMessage('');
        })
        .catch((error) => console.log(error));
    }
  };


  return (
    <Container maxWidth='sm'>
      <Grid
        container
        spacing={0}
        direction='column'
        justify='center'
        alignItems='center'
        style={{ minHeight: '100vh', marginTop: '-40px' }}
      >
        <Paper elevation={5}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: 10,
              padding: 10,
            }}
          >
            <form
              name='contact'
              className='contact-form'
              autoComplete='off'
              method='POST'
              action='/contact/?success=true'
              data-netlify='true'
              >
              <h1>Contact Form</h1>
              <input type="hidden" name="form-name" value="contact" />

              <TextField
                margin='normal'
                fullWidth
                size='small'
                id='name'
                name='name'
                label='Name'
                variant='outlined'
                value={name}
                onChange={(e) => setName(e.target.value)}
                helperText={errors.name ? 'Name is required' : ''}
                error={errors.name}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <AccountBox color='primary' />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                margin='normal'
                fullWidth
                size='small'
                id='email'
                name='email'
                label='Email'
                variant='outlined'
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                helperText={errors.email ? 'Valid email required' : ''}
                error={errors.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <EmailIcon color='primary' />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                margin='normal'
                fullWidth
                size='small'
                id='msg'
                name='message'
                label='Message'
                variant='outlined'
                multiline
                rows={3}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                helperText={errors.message ? 'Required' : ''}
                error={errors.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <MessageIcon color='primary' />
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                type='submit'
                variant='contained'
                color='primary'
                size='medium'
                endIcon={<SendIcon />}
                onClick={handleSubmit}
              >
                Send
              </Button>
            {success && (
              <Typography color='primary' style={{marginTop: '20Px'}}>
                Thanks for your message!
              </Typography>
            )}
            </form>

          </div>
        </Paper>
      </Grid>
    </Container>
  );
};

export default Contact;
