export const add_101 = [
  { i: 1, g: 101, f: 0, s: 0, r: 0 },
  { i: 2, g: 101, f: 0, s: 1, r: 1 },
  { i: 3, g: 101, f: 0, s: 2, r: 2 },
  { i: 4, g: 101, f: 0, s: 3, r: 3 },
  { i: 5, g: 101, f: 0, s: 4, r: 4 },
  { i: 6, g: 101, f: 0, s: 5, r: 5 },
  { i: 7, g: 101, f: 0, s: 6, r: 6 },
  { i: 8, g: 101, f: 0, s: 7, r: 7 },
  { i: 9, g: 101, f: 0, s: 8, r: 8 },
  { i: 10, g: 101, f: 0, s: 9, r: 9 },
  { i: 11, g: 101, f: 1, s: 0, r: 1 },
  { i: 12, g: 101, f: 1, s: 1, r: 2 },
  { i: 13, g: 101, f: 1, s: 2, r: 3 },
  { i: 14, g: 101, f: 1, s: 3, r: 4 },
  { i: 15, g: 101, f: 1, s: 4, r: 5 },
  { i: 16, g: 101, f: 1, s: 5, r: 6 },
  { i: 17, g: 101, f: 1, s: 6, r: 7 },
  { i: 18, g: 101, f: 1, s: 7, r: 8 },
  { i: 19, g: 101, f: 1, s: 8, r: 9 },
  { i: 20, g: 101, f: 2, s: 0, r: 2 },
  { i: 21, g: 101, f: 2, s: 1, r: 3 },
  { i: 22, g: 101, f: 2, s: 2, r: 4 },
  { i: 23, g: 101, f: 2, s: 3, r: 5 },
  { i: 24, g: 101, f: 2, s: 4, r: 6 },
  { i: 25, g: 101, f: 2, s: 5, r: 7 },
  { i: 26, g: 101, f: 2, s: 6, r: 8 },
  { i: 27, g: 101, f: 2, s: 7, r: 9 },
  { i: 28, g: 101, f: 3, s: 0, r: 3 },
  { i: 29, g: 101, f: 3, s: 1, r: 4 },
  { i: 30, g: 101, f: 3, s: 2, r: 5 },
  { i: 31, g: 101, f: 3, s: 3, r: 6 },
  { i: 32, g: 101, f: 3, s: 4, r: 7 },
  { i: 33, g: 101, f: 3, s: 5, r: 8 },
  { i: 34, g: 101, f: 3, s: 6, r: 9 },
  { i: 35, g: 101, f: 4, s: 0, r: 4 },
  { i: 36, g: 101, f: 4, s: 1, r: 5 },
  { i: 37, g: 101, f: 4, s: 2, r: 6 },
  { i: 38, g: 101, f: 4, s: 3, r: 7 },
  { i: 39, g: 101, f: 4, s: 4, r: 8 },
  { i: 40, g: 101, f: 4, s: 5, r: 9 },
  { i: 41, g: 101, f: 5, s: 0, r: 5 },
  { i: 42, g: 101, f: 5, s: 1, r: 6 },
  { i: 43, g: 101, f: 5, s: 2, r: 7 },
  { i: 44, g: 101, f: 5, s: 3, r: 8 },
  { i: 45, g: 101, f: 5, s: 4, r: 9 },
  { i: 46, g: 101, f: 6, s: 0, r: 6 },
  { i: 47, g: 101, f: 6, s: 1, r: 7 },
  { i: 48, g: 101, f: 6, s: 2, r: 8 },
  { i: 49, g: 101, f: 6, s: 3, r: 9 },
  { i: 50, g: 101, f: 7, s: 0, r: 7 },
  { i: 51, g: 101, f: 7, s: 1, r: 8 },
  { i: 52, g: 101, f: 7, s: 2, r: 9 },
  { i: 53, g: 101, f: 8, s: 0, r: 8 },
  { i: 54, g: 101, f: 8, s: 1, r: 9 },
  { i: 55, g: 101, f: 9, s: 0, r: 9 },
];

export const add_102 = [
  { i: 1, g: 102, f: 1, s: 1, r: 2 },
  { i: 2, g: 102, f: 1, s: 2, r: 3 },
  { i: 3, g: 102, f: 1, s: 3, r: 4 },
  { i: 4, g: 102, f: 1, s: 4, r: 5 },
  { i: 5, g: 102, f: 1, s: 5, r: 6 },
  { i: 6, g: 102, f: 1, s: 6, r: 7 },
  { i: 7, g: 102, f: 1, s: 7, r: 8 },
  { i: 8, g: 102, f: 1, s: 8, r: 9 },
  { i: 9, g: 102, f: 1, s: 9, r: 10 },
  { i: 10, g: 102, f: 1, s: 10, r: 11 },
  { i: 11, g: 102, f: 1, s: 11, r: 12 },
  { i: 12, g: 102, f: 1, s: 12, r: 13 },
  { i: 13, g: 102, f: 1, s: 13, r: 14 },
  { i: 14, g: 102, f: 1, s: 14, r: 15 },
  { i: 15, g: 102, f: 1, s: 15, r: 16 },
  { i: 16, g: 102, f: 1, s: 16, r: 17 },
  { i: 17, g: 102, f: 1, s: 17, r: 18 },
  { i: 18, g: 102, f: 1, s: 18, r: 19 },
  { i: 19, g: 102, f: 2, s: 1, r: 3 },
  { i: 20, g: 102, f: 2, s: 2, r: 4 },
  { i: 21, g: 102, f: 2, s: 3, r: 5 },
  { i: 22, g: 102, f: 2, s: 4, r: 6 },
  { i: 23, g: 102, f: 2, s: 5, r: 7 },
  { i: 24, g: 102, f: 2, s: 6, r: 8 },
  { i: 25, g: 102, f: 2, s: 7, r: 9 },
  { i: 26, g: 102, f: 2, s: 8, r: 10 },
  { i: 27, g: 102, f: 2, s: 9, r: 11 },
  { i: 28, g: 102, f: 2, s: 10, r: 12 },
  { i: 29, g: 102, f: 2, s: 11, r: 13 },
  { i: 30, g: 102, f: 2, s: 12, r: 14 },
  { i: 31, g: 102, f: 2, s: 13, r: 15 },
  { i: 32, g: 102, f: 2, s: 14, r: 16 },
  { i: 33, g: 102, f: 2, s: 15, r: 17 },
  { i: 34, g: 102, f: 2, s: 16, r: 18 },
  { i: 35, g: 102, f: 2, s: 17, r: 19 },
  { i: 36, g: 102, f: 3, s: 1, r: 4 },
  { i: 37, g: 102, f: 3, s: 2, r: 5 },
  { i: 38, g: 102, f: 3, s: 3, r: 6 },
  { i: 39, g: 102, f: 3, s: 4, r: 7 },
  { i: 40, g: 102, f: 3, s: 5, r: 8 },
  { i: 41, g: 102, f: 3, s: 6, r: 9 },
  { i: 42, g: 102, f: 3, s: 7, r: 10 },
  { i: 43, g: 102, f: 3, s: 8, r: 11 },
  { i: 44, g: 102, f: 3, s: 9, r: 12 },
  { i: 45, g: 102, f: 3, s: 10, r: 13 },
  { i: 46, g: 102, f: 3, s: 11, r: 14 },
  { i: 47, g: 102, f: 3, s: 12, r: 15 },
  { i: 48, g: 102, f: 3, s: 13, r: 16 },
  { i: 49, g: 102, f: 3, s: 14, r: 17 },
  { i: 50, g: 102, f: 3, s: 15, r: 18 },
  { i: 51, g: 102, f: 3, s: 16, r: 19 },
  { i: 52, g: 102, f: 4, s: 1, r: 5 },
  { i: 53, g: 102, f: 4, s: 2, r: 6 },
  { i: 54, g: 102, f: 4, s: 3, r: 7 },
  { i: 55, g: 102, f: 4, s: 4, r: 8 },
  { i: 56, g: 102, f: 4, s: 5, r: 9 },
  { i: 57, g: 102, f: 4, s: 6, r: 10 },
  { i: 58, g: 102, f: 4, s: 7, r: 11 },
  { i: 59, g: 102, f: 4, s: 8, r: 12 },
  { i: 60, g: 102, f: 4, s: 9, r: 13 },
  { i: 61, g: 102, f: 4, s: 10, r: 14 },
  { i: 62, g: 102, f: 4, s: 11, r: 15 },
  { i: 63, g: 102, f: 4, s: 12, r: 16 },
  { i: 64, g: 102, f: 4, s: 13, r: 17 },
  { i: 65, g: 102, f: 4, s: 14, r: 18 },
  { i: 66, g: 102, f: 4, s: 15, r: 19 },
  { i: 67, g: 102, f: 5, s: 1, r: 6 },
  { i: 68, g: 102, f: 5, s: 2, r: 7 },
  { i: 69, g: 102, f: 5, s: 3, r: 8 },
  { i: 70, g: 102, f: 5, s: 4, r: 9 },
  { i: 71, g: 102, f: 5, s: 5, r: 10 },
  { i: 72, g: 102, f: 5, s: 6, r: 11 },
  { i: 73, g: 102, f: 5, s: 7, r: 12 },
  { i: 74, g: 102, f: 5, s: 8, r: 13 },
  { i: 75, g: 102, f: 5, s: 9, r: 14 },
  { i: 76, g: 102, f: 5, s: 10, r: 15 },
  { i: 77, g: 102, f: 5, s: 11, r: 16 },
  { i: 78, g: 102, f: 5, s: 12, r: 17 },
  { i: 79, g: 102, f: 5, s: 13, r: 18 },
  { i: 80, g: 102, f: 5, s: 14, r: 19 },
  { i: 81, g: 102, f: 6, s: 1, r: 7 },
  { i: 82, g: 102, f: 6, s: 2, r: 8 },
  { i: 83, g: 102, f: 6, s: 3, r: 9 },
  { i: 84, g: 102, f: 6, s: 4, r: 10 },
  { i: 85, g: 102, f: 6, s: 5, r: 11 },
  { i: 86, g: 102, f: 6, s: 6, r: 12 },
  { i: 87, g: 102, f: 6, s: 7, r: 13 },
  { i: 88, g: 102, f: 6, s: 8, r: 14 },
  { i: 89, g: 102, f: 6, s: 9, r: 15 },
  { i: 90, g: 102, f: 6, s: 10, r: 16 },
  { i: 91, g: 102, f: 6, s: 11, r: 17 },
  { i: 92, g: 102, f: 6, s: 12, r: 18 },
  { i: 93, g: 102, f: 6, s: 13, r: 19 },
  { i: 94, g: 102, f: 7, s: 1, r: 8 },
  { i: 95, g: 102, f: 7, s: 2, r: 9 },
  { i: 96, g: 102, f: 7, s: 3, r: 10 },
  { i: 97, g: 102, f: 7, s: 4, r: 11 },
  { i: 98, g: 102, f: 7, s: 5, r: 12 },
  { i: 99, g: 102, f: 7, s: 6, r: 13 },
  { i: 100, g: 102, f: 7, s: 7, r: 14 },
  { i: 101, g: 102, f: 7, s: 8, r: 15 },
  { i: 102, g: 102, f: 7, s: 9, r: 16 },
  { i: 103, g: 102, f: 7, s: 10, r: 17 },
  { i: 104, g: 102, f: 7, s: 11, r: 18 },
  { i: 105, g: 102, f: 7, s: 12, r: 19 },
  { i: 106, g: 102, f: 8, s: 1, r: 9 },
  { i: 107, g: 102, f: 8, s: 2, r: 10 },
  { i: 108, g: 102, f: 8, s: 3, r: 11 },
  { i: 109, g: 102, f: 8, s: 4, r: 12 },
  { i: 110, g: 102, f: 8, s: 5, r: 13 },
  { i: 111, g: 102, f: 8, s: 6, r: 14 },
  { i: 112, g: 102, f: 8, s: 7, r: 15 },
  { i: 113, g: 102, f: 8, s: 8, r: 16 },
  { i: 114, g: 102, f: 8, s: 9, r: 17 },
  { i: 115, g: 102, f: 8, s: 10, r: 18 },
  { i: 116, g: 102, f: 8, s: 11, r: 19 },
  { i: 117, g: 102, f: 9, s: 1, r: 10 },
  { i: 118, g: 102, f: 9, s: 2, r: 11 },
  { i: 119, g: 102, f: 9, s: 3, r: 12 },
  { i: 120, g: 102, f: 9, s: 4, r: 13 },
  { i: 121, g: 102, f: 9, s: 5, r: 14 },
  { i: 122, g: 102, f: 9, s: 6, r: 15 },
  { i: 123, g: 102, f: 9, s: 7, r: 16 },
  { i: 124, g: 102, f: 9, s: 8, r: 17 },
  { i: 125, g: 102, f: 9, s: 9, r: 18 },
  { i: 126, g: 102, f: 9, s: 10, r: 19 },
  { i: 127, g: 102, f: 10, s: 1, r: 11 },
  { i: 128, g: 102, f: 10, s: 2, r: 12 },
  { i: 129, g: 102, f: 10, s: 3, r: 13 },
  { i: 130, g: 102, f: 10, s: 4, r: 14 },
  { i: 131, g: 102, f: 10, s: 5, r: 15 },
  { i: 132, g: 102, f: 10, s: 6, r: 16 },
  { i: 133, g: 102, f: 10, s: 7, r: 17 },
  { i: 134, g: 102, f: 10, s: 8, r: 18 },
  { i: 135, g: 102, f: 10, s: 9, r: 19 },
  { i: 136, g: 102, f: 11, s: 1, r: 12 },
  { i: 137, g: 102, f: 11, s: 2, r: 13 },
  { i: 138, g: 102, f: 11, s: 3, r: 14 },
  { i: 139, g: 102, f: 11, s: 4, r: 15 },
  { i: 140, g: 102, f: 11, s: 5, r: 16 },
  { i: 141, g: 102, f: 11, s: 6, r: 17 },
  { i: 142, g: 102, f: 11, s: 7, r: 18 },
  { i: 143, g: 102, f: 11, s: 8, r: 19 },
  { i: 144, g: 102, f: 12, s: 1, r: 13 },
  { i: 145, g: 102, f: 12, s: 2, r: 14 },
  { i: 146, g: 102, f: 12, s: 3, r: 15 },
  { i: 147, g: 102, f: 12, s: 4, r: 16 },
  { i: 148, g: 102, f: 12, s: 5, r: 17 },
  { i: 149, g: 102, f: 12, s: 6, r: 18 },
  { i: 150, g: 102, f: 12, s: 7, r: 19 },
  { i: 151, g: 102, f: 13, s: 1, r: 14 },
  { i: 152, g: 102, f: 13, s: 2, r: 15 },
  { i: 153, g: 102, f: 13, s: 3, r: 16 },
  { i: 154, g: 102, f: 13, s: 4, r: 17 },
  { i: 155, g: 102, f: 13, s: 5, r: 18 },
  { i: 156, g: 102, f: 13, s: 6, r: 19 },
  { i: 157, g: 102, f: 14, s: 1, r: 15 },
  { i: 158, g: 102, f: 14, s: 2, r: 16 },
  { i: 159, g: 102, f: 14, s: 3, r: 17 },
  { i: 160, g: 102, f: 14, s: 4, r: 18 },
  { i: 161, g: 102, f: 14, s: 5, r: 19 },
  { i: 162, g: 102, f: 15, s: 1, r: 16 },
  { i: 163, g: 102, f: 15, s: 2, r: 17 },
  { i: 164, g: 102, f: 15, s: 3, r: 18 },
  { i: 165, g: 102, f: 15, s: 4, r: 19 },
  { i: 166, g: 102, f: 16, s: 1, r: 17 },
  { i: 167, g: 102, f: 16, s: 2, r: 18 },
  { i: 168, g: 102, f: 16, s: 3, r: 19 },
  { i: 169, g: 102, f: 17, s: 1, r: 18 },
  { i: 170, g: 102, f: 17, s: 2, r: 19 },
  { i: 171, g: 102, f: 18, s: 1, r: 19 },
];
