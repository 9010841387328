import React from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, CardHeader, Grid, Paper, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { home } from './data';
import Image from '../../css/bb_640.jpg';
import AssessmentIcon from '@material-ui/icons/AssessmentOutlined';
import PlayIcon from '@material-ui/icons/PlayCircleOutline';
import { getOperationIcon } from '../../ui/utils/GetIcon';
import Jumbo from '../../ui/components/Jumbo';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  space: {
    margin: 10,
  },
  topSpace: {
    marginTop: 20,
  },
  grid: {
    display: 'flex',
  },
  paper: {
    margin: 10,
    display: 'flex',
    width: '100%',
  },
  card: {
    color: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    background: `url(${Image})`,
    // background: `linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(${Image})`,
    // background: 'linear-gradient(to right bottom, #430089, #82ffa1)',
    backgroundPosition: 'center bottom',
    '&:hover': {
      background: `linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), url(${Image})`,
      backgroundPosition: 'center bottom',
    },
  },
  content: {
    textAlign: 'center',

  },
  actions: {
    justifyContent: 'flex-end',
  },
  avatar: {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  jumbo: {
    margin: 10, 
    textAlign: 'center', 
    backgroundColor: theme.palette.primary.light,
  },
}));


const Home = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleStart = (param) => {
    history.push(`/math/${param}`);
  };

  const handleStats= (param) => {
    history.push(`/stats/${param}`);
  };

  return (
    <React.Fragment>
      <Jumbo show={true}>
        Welcome to Learning Loop
        {/* <Typography style={{margin: 20}} color='textPrimary'>
          We hope the tools provided help primary schoolers learn basic math. 
        </Typography> */}
      </Jumbo>

    <Grid container justify='center' className={classes.topSpace}>
      {home.map((data) => (
        <Grid item key={data.content} xs={12} sm={6} className={classes.grid}>
          <Paper elevation={3} className={classes.paper}>
            <Card className={classes.card}>
              <CardHeader
                title={data.header}
                // subheader='Legend'
                avatar={
                  <Avatar className={classes.avatar}>
                    {getOperationIcon(data.action)}
                  </Avatar>
                }
              />

              <CardActionArea onClick={() => handleStart(data.action)}>
                <CardContent className={classes.content}>
                  <Typography gutterBottom variant='h5' component='h2'>
                    {data.content}
                  </Typography>
                  <Typography component='p' variant='overline'>
                    {data.excerpt}
                  </Typography>
                </CardContent>
              </CardActionArea>

              <CardActions className={classes.actions}>
                <Button
                  size='small'
                  color='primary'
                  variant='contained'
                  endIcon={<PlayIcon />}
                  onClick={() => handleStart(data.action)}
                >
                  Let's do this!
                </Button>
                <Button
                  size='small'
                  color='primary'
                  variant='contained'
                  endIcon={<AssessmentIcon />}
                  onClick={() => handleStats(data.action)}
                >
                  How did I do?
                </Button>
              </CardActions>
            </Card>
          </Paper>
        </Grid>
      ))}
    </Grid>

    </React.Fragment>
  );
};

export default Home;
