class LocalStorageHelper {
  constructor({ss = 0, sc =0, sp = 0, cs = 0, ics = 0, ca = 0, ica = 0, sk = 0, trend = []}) {
    this.ss = ss; // started sets
    this.sc = sc; // completed sets
    this.sp = sp; // set points
    this.cs = cs; // correct sets
    this.ics = ics; // incorrect sets
    this.ca = ca; // correct ans
    this.ica = ica; // incorrect ans
    this.sk = sk; // skipped
    this.trend = trend;
  }

  incrementStarted() { // +1
    this.ss++;
  }

  incrementCompleted() { // +1
    this.sc++;
  }

  incrementPoints(points) {
    this.sp += points;
  }

  incrementCorrectSets() { // +1
    this.cs++;
  }

  incrementIncorrectSets() {
    this.ics++;
  }

  incrementAnswers(correct, incorrect) {
    this.ca += correct;
    this.ica += incorrect;
    this.trend.push({
      time: Date.now(),
      pct:
        Math.round((correct + Number.EPSILON )* 100) /
        ((correct + incorrect) * 100),
    });
  }

  incrementSkipped(num) {
    this.sk += num;
  }

  toJSON() {
    return {
      ss: this.ss,
      sc: this.sc,
      sp: this.sp,
      cs: this.cs,
      ics: this.ics,
      ca: this.ca,
      ica: this.ica,
      sk: this.sk,
      trend: this.trend,
    };
  }
}

export default LocalStorageHelper;
