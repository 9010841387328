export const config = [
  {
    id: 101,
    q: 10,
    p: { min: 0, max: 9, type: 'pre' },
    pi: 0.5,
  },
  {
    id: 102,
    q: 10,
    p: { min: 1, max: 19, type: 'pre' },
    pi: 0.5,
  },
  {
    id: 103,
    q: 10,
    p: { min: 10, max: 99, type: 'random' },
    pi: 0.5,
  },
  {
    id: 104,
    q: 10,
    p: { min: 50, max: 999, type: 'random' },
    pi: 0.5,
  },
  {
    id: 201,
    q: 10,
    p: { min: 0, max: 9, type: 'pre' },
    pi: 0.5,
  },
  {
    id: 202,
    q: 10,
    p: { min: 1, max: 19, type: 'pre' },
    pi: 0.5,
  },
  {
    id: 203,
    q: 10,
    p: { min: 10, max: 99, type: 'random' },
    pi: 0.5,
  },
  {
    id: 204,
    q: 10,
    p: { min: 50, max: 999, type: 'random' },
    pi: 0.5,
  },
];
