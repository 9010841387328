import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { Redo } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1,
    justifyContent: 'space-between',
  },
  table: {
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   width: '50%',
    // },
  },
  grid: {
    margin: 10,
    alignItems: 'stretch',
  },
  item: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  headingCorrect: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: 'green',
    // color: theme.palette.primary.main,
  },
  headingIncorrect: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: theme.palette.secondary.dark,
  },
  headingSkipped: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: theme.palette.text.secondary,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const ResultsAccordion = ({results, symbol, stats}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState('panel2');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell align='center'>#</TableCell>
          <TableCell align='center'>Question</TableCell>
          <TableCell align='center'>Answer</TableCell>
          <TableCell align='center'>Correct</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  return (
    <React.Fragment>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        disabled={stats.correct === 0 ? true : false}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.headingCorrect}>
            <CheckCircleOutlinedIcon />
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {stats.correct} of {stats.total}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper} className={classes.table}>
            <Table size='small'>
              {getTableHeader()}
              <TableBody>
                {/* eslint eqeqeq: 0 */}
                {results.filter(row => row.r == row.e).map(row => (
                  <TableRow key={row.i}>
                    <TableCell align='center'>{row.i + 1}</TableCell>
                    <TableCell align='center'>{`${row.f} ${symbol} ${row.s}`}</TableCell>
                    <TableCell align='center'>{row.r}</TableCell>
                    <TableCell align='center'>{row.e}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
        disabled={stats.incorrect === 0 ? true : false}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.headingIncorrect}>
            <CancelOutlinedIcon />
          </Typography>
          <Typography className={classes.secondaryHeading}>
          {stats.incorrect} of {stats.total}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* <Typography>Add a table?</Typography> */}
          <TableContainer component={Paper} className={classes.table}>
            <Table size='small'>
              {getTableHeader()}
              <TableBody>
                {/* eslint eqeqeq: 0 */}
                {results.filter(row => row.r != row.e && row.t !== 'skip').map(row => (
                  <TableRow key={row.i}>
                    <TableCell align='center'>{row.i + 1}</TableCell>
                    <TableCell align='center'>{`${row.f} ${symbol} ${row.s}`}</TableCell>
                    <TableCell align='center'>{row.r}</TableCell>
                    <TableCell align='center'>{row.e}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
        disabled={stats.skipped === 0 ? true : false}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.headingSkipped}>
            <Redo />
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {stats.skipped} of {stats.total}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* <Typography>Add a table here?</Typography> */}
          <TableContainer component={Paper} className={classes.table}>
            <Table size='small'>
              {getTableHeader()}
              <TableBody>
                {/* eslint eqeqeq: 0 */}
                {results.filter(row => row.t === 'skip').map(row => (
                  <TableRow key={row.i}>
                    <TableCell align='center'>{row.i + 1}</TableCell>
                    <TableCell align='center'>{`${row.f} ${symbol} ${row.s}`}</TableCell>
                    <TableCell align='center'>{row.r}</TableCell>
                    <TableCell align='center'>{row.e}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}

export default ResultsAccordion;