export const getFullOpName = key => {
  switch (key) {
    case 'add':
      return 'Addition';
    case 'sub':
      return 'Subtraction';
    case 'mult':
      return 'Multiplication';
    case 'div':
      return 'Division';  
    default:
      return undefined;  
  }
};

export const getOpSymbol = topic => {
  switch (topic) {
    case 'add':
      return '+';
    case 'sub':
      return '-';
    case 'mult':
      return 'x';
    case 'div':
      return '/';  
    default:
      return '?';  
  }
};