import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router';

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Snackbar,
  SnackbarContent,
  TextField,
} from '@material-ui/core';
import { getOperationIcon } from '../../ui/utils/GetIcon';
import { fetch } from '../../utils/DataHandler';
import {
  PlayCircleOutline,
  ThumbUpOutlined,
  ThumbDownOutlined,
} from '@material-ui/icons';
import Jumbo from '../../ui/components/Jumbo';
import HomeCard from '../../ui/components/HomeCard';
import { options } from './options';
import RelaxedResults from './RelaxedResults';
import { config } from '../../config/quizSetup';
import { updateStats } from '../../utils/LocalStorageHandler';
import { getOpSymbol } from '../../utils/getOpName';

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'flex',
  },
  topSpace: {
    marginTop: 20,
  },
  form: {
    width: '100%',
    margin: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const RelaxedMode = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const responseRef = useRef();
  const [qList, setQList] = useState(null);
  const [currentQ, setCurrentQ] = useState(0);
  const [response, setResponse] = useState('');
  const [isQuizComplete, setIsQuizComplete] = useState(false);
  const [responses, setResponses] = useState([]);
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedbackIcon, setFeedbackIcon] = useState(<PlayCircleOutline />);
  const [inputError, setInputError] = useState(false);
  const [quizConfig, setQuizConfig] = useState(undefined);

  const closeFeedback = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowFeedback(false);
  };

  const computeStats = useCallback(() => {
    if (!isQuizComplete) return;
    
    let correct   = 0;
    let incorrect = 0;
    let skipped   = 0;
    responses.forEach(rec => {
      if (rec.r) { // val exists
        // eslint-disable-next-line
        if (rec.r == rec.e) {
          correct++;
        } else {
          incorrect++;
        }
      } else { // skipped
        skipped++;
        incorrect++;
      }
    });

    let correctSet   = 0;
    let incorrectSet = 0;
    let points       = 0;
    if (correct === quizConfig.q) {
      correctSet++;
      points = quizConfig.pi;
    } else {
      incorrectSet++;
    }

    // Update stats in local state
    updateStats(props.match.params.id, {
      sc: 1,
      sp: points,
      cs: correctSet,
      ics: incorrectSet,
      ca: correct,
      ica: incorrect,
      sk: skipped
    });
  }, [isQuizComplete, props.match.params.id, quizConfig, responses]);

  const handleSubmit = (event, type) => {
    event.preventDefault();
    setInputError(false);

    // submit with no response || enter with no response
    if ((type === 'submit' && !response) || (!type && !response)) {
      setInputError(true);
      return;
    }

    setResponses((oldArray) => [
      ...oldArray,
      {
        i: currentQ,
        r: response,
        t: type,
        f: qList[currentQ].f,
        s: qList[currentQ].s,
        e: qList[currentQ].r,
      },
    ]);
    
    // eslint-disable-next-line
    if (response == qList[currentQ].r) {
      setFeedbackIcon(<ThumbUpOutlined/>);
    } else {
      setFeedbackIcon(<ThumbDownOutlined/>);
    }

    if (currentQ + 1 < qList.length) {
      setCurrentQ(currentQ + 1);
      setResponse('');
    } else {
      setIsQuizComplete(true);
    }

    setShowFeedback(true);
  }

  const getOptionContent = (id, parameter) => {
    const obj = options.find((item) => item.id === parseInt(id, 10));
    if (obj) {
      return obj[parameter];
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    setQuizConfig(config.find(item => item.id === parseInt(props.match.params.id, 10)));
  }, [setQuizConfig, props.match.params.id]);

  useEffect(() => {
    if (quizConfig) {
      setQList(fetch(parseInt(props.match.params.id, 10), quizConfig.q)); // 10
    }
  }, [setQList, quizConfig, props.match.params.id]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const listSize = qList ? qList.length : 0;
      if (currentQ + 1 < listSize) { // don't focus when results are shown
        responseRef.current.focus();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentQ, qList]);

  useEffect(() => { // mark the quiz as started
    updateStats(props.match.params.id, { ss: 1 });
  }, [props.match.params.id]);

  useEffect(() => {
    computeStats();
  }, [isQuizComplete, computeStats]);

  return (
    <div>
      <Jumbo show={getOptionContent(props.match.params.id, 'header')}>
        {getOptionContent(props.match.params.id, 'header')}
      </Jumbo>

      {getOptionContent(props.match.params.id, 'header') === undefined && (
        <Grid container justify='center' className={classes.topSpace}>
          <HomeCard
            header='Oops!'
            subheader='404 - App did a bombad boo!'
            content='Great job experimenting!'
            excerpt={`Unfortulately, we do not know how to ${props.match.params.id}`}
            avatar={getOperationIcon(props.match.params.id)}
            action1={(loc) => history.push(loc)}
            selection1='/'
            action1Text='Take me home'
            action1Icon={<PlayCircleOutline />}
          />
        </Grid>
      )}

      {(qList === null || qList === undefined) &&
        getOptionContent(props.match.params.id, 'header') !== undefined && (
          <Grid container justify='center' className={classes.topSpace}>
            <CircularProgress color='secondary' />
          </Grid>
        )}

      {qList !== null && qList !== undefined && !isQuizComplete && (
        <Grid container justify='center' className={classes.topSpace}>
          <Grid
            item
            xs={12}
            sm={6}
            className={classes.grid}
            key={props.match.params.id}
          >
            <HomeCard
              header={getOptionContent(props.match.params.id, 'header')}
              content={`${qList[currentQ].f} ${getOpSymbol(getOptionContent(props.match.params.id, 'topic'))} ${qList[currentQ].s}`}
              excerpt=''
              avatar={getOperationIcon(
                getOptionContent(props.match.params.id, 'topic')
              )}
              action1={() => {}}
              selection1={props.match.params.id}
              action1Color='default'
              action1Text={`${currentQ + 1} of ${qList.length}`}
              // action1Icon={<PlayCircleOutline />}
            />
          </Grid>

          <Grid container item xs={12} sm={6}>
            <form
              autoComplete='off'
              onSubmit={handleSubmit}
              className={classes.form}
            >
              <TextField
                autoFocus
                inputRef={responseRef}
                // inputProps={{style: {textAlign: 'center'}}}
                margin='normal'
                fullWidth
                size='small'
                id='resp'
                label='Answer'
                variant='filled'
                type='number'
                value={response}
                error={inputError}
                helperText={inputError ? 'Either skip or enter a value': ''}
                onChange={(e) => setResponse(e.target.value)}
              />
              <Box>
                <Button
                  color='default'
                  size='large'
                  variant='contained'
                  onClick={(e) => handleSubmit(e, 'skip')}
                  style={{ marginRight: 10 }}
                >
                  Skip
                </Button>
                <Button
                  color='primary'
                  size='large'
                  variant='contained'
                  onClick={(e) => handleSubmit(e, 'submit')}
                  style={{ marginLeft: 10 }}
                >
                  Submit
                </Button>
              </Box>

              {/* <Typography
                component='p'
                variant='subtitle2'
                color='textSecondary'
              >
                {response ? 'Click on submit or hit enter' : '.'}
              </Typography> */}

            </form>
          </Grid>
        </Grid>
      )}

      <Snackbar
        open={showFeedback}
        autoHideDuration={1500}
        onClose={closeFeedback}
      >
        <SnackbarContent message={feedbackIcon} />
      </Snackbar>

      {isQuizComplete && (
        <Grid container justify='center' className={classes.topSpace}>
          <RelaxedResults 
            results={responses} 
            symbol={getOpSymbol(getOptionContent(props.match.params.id, 'topic'))} />
        </Grid>
      )}
    </div>
  );
};

export default RelaxedMode;
