import Icon from "@mdi/react";
import {
  mdiDivision, mdiPlus, mdiClose, mdiMinus, mdiEmoticonWink
} from "@mdi/js";

export const getOperationIcon = (key) => {
  switch (key) {
    case 'add':
      return <Icon path={mdiPlus} />;
    case 'sub':
      return <Icon path={mdiMinus}/>;
    case 'mult':
      return <Icon path={mdiClose}/>;
    case 'div':
      return <Icon path={mdiDivision}></Icon>;  
    default:
      return <Icon path={mdiEmoticonWink}></Icon>;  
  }
}
