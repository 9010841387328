import store from 'store';
import LocalStorageHelper from './LocalStorageHelper';
// { ss: started, sc: completed, sp: points, 
//   cs: correct sets, ics: incorrect sets, 
//   ca: correct answers, ica: incorrect answers 
//   sk: skipped
// }
export const started = id => { // UNUSED - always use updateStats()
  let storedDoc = store.get(id)
    ? store.get(id)
    : { ss: 0, sc: 0, sp: 0, cs: 0, ics: 0, ca: 0, ica: 0, sk: 0 };

  store.set(id, {...storedDoc, s: storedDoc.s + 1 });
};

export const updateStats = (id, obj) => {
  const storedDoc = store.get(id) || {};
  const lsh = new LocalStorageHelper(storedDoc);

  if (obj.ss) { // +1 if truthy
    lsh.incrementStarted();
  }

  if (obj.sc) { // +1 if truthy
    lsh.incrementCompleted();
  }

  if (obj.sp) { // value matters
    lsh.incrementPoints(obj.sp);
  }

  if (obj.cs) { // +1 if truthy
    lsh.incrementCorrectSets(obj.cs);
  }

  if (obj.ics) { // +1 if truthy
    lsh.incrementIncorrectSets(obj.ics);
  }

  if (obj.ca || obj.ica) { // value matters
    lsh.incrementAnswers(obj.ca, obj.ica);
  }

  if (obj.sk) { // value matters
    lsh.incrementSkipped(obj.sk);
  }

  store.set(id, lsh.toJSON());
  return lsh.toJSON();
};

export const getStatsById = id => {
  return store.get(id) || {};
};