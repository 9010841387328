// export const getUniqueRandomInts = (quantity, max) => {
//   const set = new Set();
//   while (set.size < quantity) {
//     set.add(Math.floor(Math.random() * (max + 1)));
//   }
//   return set;
// };

// export const getUniqueRandomIntsEx0 = (quantity, max) => { // not used
//   const set = new Set();
//   while (set.size < quantity) {
//     set.add(Math.floor(Math.random() * max) + 1);
//   }
//   return set;
// };

// export const getRandomInt = max => { // not used
//   return Math.floor(Math.random() * (max + 1));
// };

// export const getRandomIntEx0 = max => { // not used
//   return Math.floor(Math.random() * max) + 1;
// };

export const getUniqueRandomInts = (max, min = 0, quantity = 10) => {
  const set = new Set();
  while (set.size < quantity) {
    set.add(getRandomIntFromInterval(min, max));
  }
  return set;
}

export const getRandomIntFromInterval = (min, max) => { // min and max inclusive 
  return Math.floor(Math.random() * (max - min + 1) + min);
};