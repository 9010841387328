import React from 'react';

const Stats = (props) => {
  return (
    <div>
      <h1>Stats: {props.match.params.topic}</h1>
    </div>
  );
}

export default Stats;