import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Grid, makeStyles } from '@material-ui/core';
import { getOperationIcon } from '../../ui/utils/GetIcon';
import { getFullOpName } from '../../utils/getOpName';
import PlayIcon from '@material-ui/icons/PlayCircleOutline';
import HomeCard from '../../ui/components/HomeCard';
import { options } from './options';
import Jumbo from '../../ui/components/Jumbo';
import { getStatsById } from '../../utils/LocalStorageHandler';

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'flex',
  },
  topSpace: {
    marginTop: 20,
  }
}));

const Math = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [topic, setTopic] = useState([]);

  const handleAction1 = value => {
    history.push(`/math/relaxed/${value}`);
  };

  useEffect(() => {
    setTopic(options.filter((item) => item.topic === props.match.params.topic));
  }, [props.match.params.topic]);

  return (
    <div>
      {topic.length === 0 && (
        <Grid container justify='center' className={classes.topSpace}>
          <HomeCard
            header='Oops!'
            subheader='404 - App did a bombad boo!'
            content='Great job experimenting!'
            excerpt={`Unfortulately, we do not know how to ${props.match.params.topic}`}
            avatar={getOperationIcon(props.match.params.topic)}
            action1={(loc) => history.push(loc)}
            selection1='/'
            action1Text='Take me home'
            action1Icon={<PlayIcon />}
          />
        </Grid>
      )}

      <Jumbo show={topic.length !== 0}>
        {getFullOpName(props.match.params.topic)}
      </Jumbo>

      <Grid container justify='center' className={classes.topSpace}>
        {topic.map((option) => (
          <Grid item key={option.id} xs={12} sm={6} className={classes.grid}>
            <HomeCard
              header={option.header}
              content={option.content}
              excerpt={option.excerpt}
              avatar={getOperationIcon(props.match.params.topic)}
              action1={handleAction1}
              selection1={option.id}
              action1Text='Start'
              rating={getStatsById(option.id).sp ? getStatsById(option.id).sp : undefined}
              action1Icon={<PlayIcon />}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Math;