import React from 'react';

import { makeStyles, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  jumbo: {
    margin: '20px 10px 10px 10px', 
    textAlign: 'center', 
    // backgroundColor: theme.palette.primary.light,
    opacity: 0.7
  },
}));

const Jumbo = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      {props.show && (
        <Paper variant='outlined' className={classes.jumbo}>
          <Typography style={{ margin: 20 }} variant='h5' color='textPrimary'>
            {props.children}
          </Typography>
        </Paper>
      )}
    </React.Fragment>
  );
}

export default Jumbo;