import { getRandomIntFromInterval, getUniqueRandomInts } from './RandomNumber';
import { add_101, add_102 } from '../data/add';
import { sub_201, sub_202 } from '../data/sub';

const fetchAddData = (id, size = 10) => {
  let doc = [];
  if (id === 101) { // use pre-generated data (< 10)
    const uniqueIntSet = getUniqueRandomInts(54, 0, size); // with 0 makes 55
    uniqueIntSet.forEach(value => doc.push(add_101[value]));
  } else if (id === 102)  { // use pre-generated data (< 20)
    const uniqueIntSet = getUniqueRandomInts(170, 0, size); // with 0 makes 171
    uniqueIntSet.forEach(value => doc.push(add_102[value]));
  } else if (id === 103)  { // use random range bound data (<100)
    const min = 10;
    const max = 99;
    const resultSet = new Set();
    let counter = 1;
    while (resultSet.size < size) { // random 10 <= f <= 89; 10 <= s <= 89
      const prevSetSize = resultSet.size;
      const fNo = getRandomIntFromInterval(min, max - min); // min, max-min
      const sNo = getRandomIntFromInterval(min, max - fNo); // min, max - first
      const ans = fNo + sNo;
      const obj = {i: counter + 1, g: 103, f: fNo, s: sNo, r: ans};

      resultSet.add(JSON.stringify(obj, ['r'])); // discard if sum result already seen 
      if (resultSet.size > prevSetSize) {
        doc.push(obj);
      }
      counter++;
    }
  } else if (id === 104)  { // use random range bound data (<1000) 
    const min = 50;
    const max = 999;
    const resultSet = new Set();
    let counter = 1;
    while (resultSet.size < size) { // random 50 <= f <= 949; 50 <= s <= 949
      const prevSetSize = resultSet.size;
      const fNo = getRandomIntFromInterval(min, max - min); // min, max-min
      const sNo = getRandomIntFromInterval(min, max - fNo); // min, max - first
      const ans = fNo + sNo;
      const obj = {i: counter + 1, g: 104, f: fNo, s: sNo, r: ans};

      resultSet.add(JSON.stringify(obj, ['r'])); // discard if sum result already seen 
      if (resultSet.size > prevSetSize) {
        doc.push(obj);
      }
      counter++;
    }
  } else {
    return undefined;
  }

  return doc;
};

const fetchSubData = (id, size = 10) => {
  let doc = [];

  if (id === 201) { // use pre-generated data (< 10)
    const uniqueIntSet = getUniqueRandomInts(54, 0, size); // with 0 makes 55
    uniqueIntSet.forEach(value => doc.push(sub_201[value]));
  } else if (id === 202)  { // use pre-generated data (< 20)
    const uniqueIntSet = getUniqueRandomInts(170, 0, size); // with 0 makes 171
    uniqueIntSet.forEach(value => doc.push(sub_202[value]));
  } else if (id === 203) {
    const min = 10;
    const max = 99;
    const resultSet = new Set();
    let counter = 1;
    while (resultSet.size < size) { 
      const prevSetSize = resultSet.size;
      const fNo = getRandomIntFromInterval(min + min, max); // min, max-min
      const sNo = getRandomIntFromInterval(min, fNo - min); // min, max - first
      const ans = fNo - sNo;
      const obj = {i: counter + 1, g: 203, f: fNo, s: sNo, r: ans};

      resultSet.add(JSON.stringify(obj, ['r'])); // discard if sum result already seen 
      if (resultSet.size > prevSetSize) {
        doc.push(obj);
      }
      counter++;
    }
  } else if (id === 204) {
    const min = 50;
    const max = 999;
    const resultSet = new Set();
    let counter = 1;
    while (resultSet.size < size) { 
      const prevSetSize = resultSet.size;
      const fNo = getRandomIntFromInterval(min + min, max); // min, max-min
      const sNo = getRandomIntFromInterval(min, fNo - min); // min, max - first
      const ans = fNo - sNo;
      const obj = {i: counter + 1, g: 204, f: fNo, s: sNo, r: ans};

      resultSet.add(JSON.stringify(obj, ['r'])); // discard if sum result already seen 
      if (resultSet.size > prevSetSize) {
        doc.push(obj);
      }
      counter++;
    }
  } else {
    return undefined;
  }

  return doc;
};

const fetchMultData = (id, size = 10) => {
  return undefined;
};

const fetchDivData = (id, size = 10) => {
  return undefined;
};

export const fetch = (id, size = 10) => {

  if (id < 200) {
    return fetchAddData(id, size);
  } else if (id < 300 ) {
    return fetchSubData(id, size);
  } else if (id < 400 ) {
    return fetchMultData(id, size);
  } else if (id < 500 ) {
    return fetchDivData(id, size);
  } else {
    return undefined;
  }

};
