import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';

import Home from './pages/home/Home';
import Contact from './pages/contact';
import About from './pages/about';
import Math from './pages/math/Math';
import Stats from './pages/stats';
import RelaxedMode from './pages/math/RelaxedMode';
import { Container } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Image from './css/memphis-colorful.png';
import ResponsiveNav from './nav/ResponsiveNav';

const App = () => {
  
  const theme = createMuiTheme({
    palette: {
      // background: {
      //   default: '#eaeaea',
      // },
      // primary: {
      //   main: '#c6ff00',
      //   light: '#fdff58',
      //   dark: '#90cc00'
      // },
      // secondary: {
      //   main: '#ffff00',
      //   light: '#ffff5a',
      //   dark: '#c7cc00'
      // },
      // text: {
      //   primary: '#000000',
      //   secondary: '#000000'
      // },
      breakpoints: {
        values: {
          xs: 0, // xs >= 0px
          sm: 600, // sm >= 600px
          md: 960, // md >= 960px
          lg: 1280, // lg >= 1280px
          xl: 1920, // xl >= 1920px
        },
      },
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          body: {
            backgroundImage: `url(${Image})`,
          }
        }
      },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>

        <ResponsiveNav />
        <Container>
          <Switch>
            <Route exact path={['/', '/home']} component={Home} />
            <Route exact path='/about' component={About} />
            <Route exact path='/contact' component={Contact} />
            <Route exact path='/math/:topic' component={Math} />
            <Route exact path='/stats/:topic' component={Stats} />
            <Route exact path='/math/relaxed/:id' component={RelaxedMode} />
            <Redirect to='/' />
          </Switch>
        </Container>

      </BrowserRouter>
    </MuiThemeProvider>
  );
};

export default App;
